import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md'

function DataHub({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(6) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className="tab-content-div">
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    {/* <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/long-term-roi/14.jpg')} alt="" className='frames-image' />
                                    </div> */}
                                    <div className="col-12 col-lg-12 content-div">
                                        <h6 className='heading'>This is how it works</h6>
                                        <h6 className='sub-heading'>1. Collection</h6>
                                        <div className="sub-content-div">
                                            <p>We collect data from 100s of data sources.</p>
                                            <ul className='row list-style-dot gx-0 mt-3'>
                                                <li className="col-4">
                                                    <p>Websites</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>FEP</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Affiliate Programs</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>TV</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Print</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Out-of-home</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Coupons</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Ibotta</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Fetch</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Amazon</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Target</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Walmart</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Kroger</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Instacard</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Gopuff</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Doordash</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Influencer</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>PR</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Amazon Ads</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Bing Ads</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Google Ads</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Facebook</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Insta</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Twitter</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Tiktok</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Amazon DSP</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Stackadapt</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Offline Sales</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Online Sales</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Holidays</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>TDP</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Temperature</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Super Bowl</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>NBA</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Major league baseball</p>
                                                </li>
                                            </ul>
                                            
                                            {/* <div className='row mt-3'>
                                                <div className="col-12 col-lg-3">
                                                    <p>1. Websites</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>2. FEP</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>3. Affiliate Programs</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>4. TV</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>5. Print</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>6. Out-of-home</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>7. Coupons</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>8. Ibotta</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>9. Fetch</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>10. Amazon</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>11. Target</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>12. Walmart</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>13. Kroger</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>14. Instacard</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>15. Gopuff</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>16. Doordash</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>17. Influencer</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>18. PR</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>19. Amazon Ads</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>20. Bing Ads</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>21. Google Ads</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>22. Facebook</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>23. Insta</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>24. Twitter</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>25. Tiktok</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>26. Amazon DSP</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>27. Stackadapt</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>28. Offline Sales</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>29. Online Sales</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>30. Holidays</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>31. TDP</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>32. Temperature</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>33. Super Bowl</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>34. NBA</p>
                                                </div>
                                                <div className="col-12 col-lg-3">
                                                    <p>35. Major league baseball</p>
                                                </div>
                                            </div> */}
                                            {/* <p>Websites, FEP, Affiliate Programs, TV, Print, Out-of-home,  Coupons, Ibotta, Fetch, Amazon, Target, Walmart, Kroger, Instacard, Gopuff, Doordash, Influencer, PR, Amazon Ads, Bing Ads, Google Ads, Facebook, Insta, Twitter, Tiktok, Amazon DSP, Stackadapt, Offline Sales, Online Sales, Holidays, TDP, Temperature, Super Bowl, NBA, Major league baseball</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/datahub/2.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Multi-Tiered Data Lake for Marketing Insights</h6>
                                        <div className="sub-content-div">
                                            <p>Our system features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Organized layers: Raw, Cleaned, and Curated data</p>
                                                </li>
                                                <li>
                                                    <p>Comprehensive metadata management capturing:</p>
                                                    <ul className='list-style-hyphen'>
                                                        <li>
                                                            <p>Campaign structure/hierarchy</p>
                                                        </li>
                                                        <li>
                                                            <p>Channel/medium taxonomies</p>
                                                        </li>
                                                        <li>
                                                            <p>Audience segment definitions</p>
                                                        </li>
                                                        <li>
                                                            <p>Creative assets metadata</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p>Key capabilities:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Automated data discovery and cataloging</p>
                                                </li>
                                                <li>
                                                    <p>Up-to-date inventory of marketing data assets</p>
                                                </li>
                                                <li>
                                                    <p>Delta lake technologies for:</p>
                                                    <ul className='list-style-hyphen'>
                                                        <li>
                                                            <p>Versioning</p>
                                                        </li>
                                                        <li>
                                                            <p>Time travel capabilities</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>Benefit: Efficient, organized, and accessible marketing data for enhanced insights and decision-making.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Harmonisation</h6>
                                        <div className="sub-content-div">
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>ML-driven automated schema inference and mapping across channels</p>
                                                </li>
                                                <li>
                                                    <p>Robust data quality framework enforcing marketing-specific rules</p>
                                                </li>
                                                <li>
                                                    <p>Custom data cleansing and standardization pipelines for:</p>
                                                    <ul className='list-style-hyphen'>
                                                        <li>
                                                            <p>Normalizing campaign names</p>
                                                        </li>
                                                        <li>
                                                            <p>Channel classifications</p>
                                                        </li>
                                                        <li>
                                                            <p>Geographic and demographic data</p>
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>Semantic layer defining consistent marketing metrics</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Unified view across disparate platforms</p>
                                                </li>
                                                <li>
                                                    <p>Enforced data consistency</p>
                                                </li>
                                                <li>
                                                    <p>Standardized marketing terminology</p>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>Result: Cleaner, more consistent data for accurate analysis and decision-making.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/datahub/3.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 text-lg-start image-div">
                                        <img src={require('../../../assets/images/datahub/4.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Unification: Comprehensive Data Integration</h6>
                                        <div className="sub-content-div">
                                            <p>Our platform seamlessly integrates diverse data sources:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Paid, owned, and earned media</p>
                                                </li>
                                                <li>
                                                    <p>Trade, shopper, and retail data</p>
                                                </li>
                                                <li>
                                                    <p>Sales and external factors</p>
                                                </li>
                                            </ul>
                                            <p>Key advantages:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Connected Intelligence: Holistic cross-dimensional insights</p>
                                                </li>
                                                <li>
                                                    <p>Agile Decision-Making: Swift responses to market changes</p>
                                                </li>
                                                <li>
                                                    <p>Optimization: Fine-tuned cross-channel strategies</p>
                                                </li>
                                                <li>
                                                    <p>Coherent Data Foundation: Unified, reliable source</p>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>Benefit: Empowers businesses with agile, data-driven decision-making, driving growth and competitiveness in dynamic markets.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DataHub
