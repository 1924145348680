import React from 'react'
import "./screen6.scss"

function Screen6({ activePage, setActivePage }) {

  return (
    <div className={'full-screen screen6'} key="screen6">
      <div className="bg-screen"></div>
      <div className="mask"></div>
      <div className="ellipse"></div>
      <div className="content about-700-div">
        <h3 className='redText'>Our Purpose</h3>
        <p className='para'>(Marketing Imagination)<sup>A.I</sup></p>
        <h3 className='redText'>Our Origin Story</h3>
        <p className='para'>
          In the world of marketing, traditional marketing measurement techniques are <br />
          the Goliath - a flawed giant everyone fears to challenge.
        </p>
        <p className='para'>
          We are David, the underdog ready to tackle the elephant in the room.
        </p>
        <p className='para'>
          We're the worker ants, small but mighty, <br />
          unafraid to acknowledge what others won't. <br />
          That current measurement methods are inadequate.
        </p>
        <p className='para'>
          Through persistence and A.I, <br />
          we're taking down the outdated giant, <br />
          revolutionizing marketing measurement one insight at a time.
        </p>
        <p className='para'>
          Join us in reshaping the landscape of marketing analytics.
        </p>
        <h3 className='redText'>Our Values</h3>
        <p className='para'>Courage, Chutzpah, Collaboration</p>
      </div>
    </div>
  )
}

export default Screen6