import React, { useRef, useState } from 'react'
import "./screen21.scss"
import backgroundVideo from "../../assets/cockpit-video.mp4"

function Screen21({activePage, setActivePage}) {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className={'full-screen screen21'} key="screen21">
            {/* <div className="background-image"></div> */}
            {!isLoaded && 
            <div className="background-image"></div>
            }
            <video
                className={isLoaded ? "background-video" : "d-none"}
                ref={videoRef}
                src={backgroundVideo}
                onLoadedData={() => { setIsLoaded(true) }}
                autoPlay loop muted>
            </video>
            <div className="mask"></div>
            <div className="cockpit-top"></div>
            <div className="cockpit-bottom"></div>
            <div className="ellipse"></div>
            <div className="content">
                <div className="text-center">
                    <img src={require('../../assets/images/TAIC.png')} alt="" className='topImg' />
                </div>
                <h4 className="text">
                    {/* The AI Cockpit is a quantum leap forward into <br />
                        hyper-intelligent analytics and unparalleled customer insights. <br />
                        Its your playground. While our AI slogs, you just sit back and play. */}
                    It's a unified growth planning tool. <br />
                    Measure the total incremental impact of interconnected business drivers: <br />
                    Brand Marketing, Shopper Marketing, Commerce Marketing, Trade Promotions, and more.<br />
                    Achieve 90%+ forecasting accuracy with our Non-linear Multivariate Forecasting and Optimization engine.
                    {/* It's a unified growth planning tool. Measure the total incremental <br /> 
                        impact of interconnected business drivers: brand marketing, shopper marketing, <br />
                        commerce marketing, trade promotions, and more. Thanks to 90%+ forecasting <br /> 
                        accuracy with non-linear multivariate forecasting and optimization. */}
                </h4>
            </div>
        </div>
    )
}

export default Screen21