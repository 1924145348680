import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function ExplainableAi({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(5) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className="tab-content-div">
                            {<div className="banner-section">
                                {/* <div className="row">
                                    <div className="col-12">
                                        <h6 className='heading'>Explainable AI - The How</h6>
                                        <p>
                                            The inner workings of neural networks have been blackboxes, Our XAI peers through a keyhole into the network’s decision making process and unveils the secrets hidden within. With feature attribution, we find which ingredient has the strongest influence, say on a dish’s flavor :
                                        </p>
                                    </div>
                                </div> */}
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className='heading'>Explainable AI - The How</h6>
                                                <p>
                                                    The inner workings of neural networks have been blackboxes, Our XAI peers through a keyhole into the network’s decision making process and unveils the secrets hidden within. With feature attribution, we find which ingredient has the strongest influence, say on a dish’s flavor :
                                                </p>
                                            </div>
                                        </div>
                                        <h6 className='sub-heading'>1. Deep Learning: Harnessing Neural Networks</h6>
                                        <div className="sub-content-div">
                                            <p>Deep Learning uses multi-layered neural networks to:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Learn hierarchical data representations</p>
                                                </li>
                                                <li>
                                                    <p>Extract abstract features from raw inputs</p>
                                                </li>
                                                <li>
                                                    <p>Identify complex patterns and relationships</p>
                                                </li>
                                            </ul>
                                            <p>Advantages:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Tackles intricate problems with high accuracy</p></li>
                                                {/* <li><p>Excels in image/speech recognition, NLP, and complex decision-making</p></li> */}
                                                <li><p>Mimics human brain's neural structure</p></li>
                                                <li><p>Effective with unstructured data</p></li>
                                            </ul>
                                            <p className='mb-0'>This approach enables sophisticated pattern recognition and generalization, uncovering insights traditional algorithms might miss.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/explainable-ai/5.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/explainable-ai/6.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Explainable AI: Transparency in Deep Learning</h6>
                                        <div className="sub-content-div">
                                            <p>XAI addresses the "black box" nature of deep learning models by:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Revealing importance of input features</p>
                                                </li>
                                                <li>
                                                    <p>Illuminating factors driving predictions</p>
                                                </li>
                                                <li>
                                                    <p>Interpreting model behavior</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Enhances trust in AI decisions</p></li>
                                                <li><p>Enables validation of model reasoning</p></li>
                                                <li><p>Identifies potential biases</p></li>
                                                <li><p>Improves model debugging</p></li>
                                                <li><p>Supports regulatory compliance</p></li>
                                            </ul>
                                            <p className='mb-0'>XAI bridges complex algorithms and human understanding, facilitating informed decision-making and responsible AI deployment. It ensures AI aligns with expectations and domain knowledge across applications.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Explainability Through Missingness Analysis</h6>
                                        <div className="sub-content-div">
                                            <p>Enhance AI explainability by examining feature missingness:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Remove/add features to observe impact on model decisions</p>
                                                </li>
                                                <li>
                                                    <p>Gauge importance of each feature</p>
                                                </li>
                                                <li>
                                                    <p>Reveal crucial information for predictions</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Identifies critical features</p></li>
                                                <li><p>Assesses model robustness to incomplete data</p></li>
                                                <li><p>Uncovers potential biases/vulnerabilities</p></li>
                                                <li><p>Provides insights on input reliance and generalization</p></li>
                                            </ul>
                                            <p className='mb-0'>This approach offers intuitive explanations for non-technical stakeholders, highlighting key decision factors and model adaptability. It supports transparent, trustworthy AI systems.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/explainable-ai/7.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExplainableAi
