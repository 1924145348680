import React from 'react'
import "./fullscreen.scss"
import VideoPlayer from '../videoplayer/VideoPlayer';

function FullScreen({ activePage, setActivePage }) {

    return (
        <div className={'full-screen fullVideo'} key="fullVideo">
            <div className="bg-screen"></div>
            <div className="mask"></div>
            <div className="ellipse"></div>
            <div className="content bootstrap-content">
                <VideoPlayer activePage={activePage} setActivePage={setActivePage} />
            </div>
        </div>
    )
}

export default FullScreen