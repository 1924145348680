import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function NonlinearOptimization({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(5) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className="tab-content-div">
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='heading'>Liberating ROI Optimization from the curse of linear world: Saturation curves</h6>
                                        <p>Farewell, marketing ROI dinosaur! The reign of saturation curves is over. These outdated tools, with their simplistic linear view, fail to capture the complex, interconnected reality of modern marketing. Their limitations blind us to hidden opportunities and synergies. Enter the era of AI-driven, multivariate optimization. Embrace Data POEM's revolutionary approach, unlocking the true potential of your marketing efforts in our dynamic, nonlinear world.</p>
                                        <h6 className='sub-heading'>1. Advanced Optimization with Neural Networks</h6>
                                        <div className="sub-content-div">
                                            <p>Our optimizer leverages neural networks to predict business KPIs, using:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Gradient descent and non-linear metaheuristics</p>
                                                </li>
                                                <li>
                                                    <p>Complex feature interactions and non-linear relationships</p>
                                                </li>
                                            </ul>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Navigates complex decision spaces effectively</p></li>
                                                <li><p>Combines efficient local search with broad exploration</p></li>
                                                <li><p>Avoids local optima traps</p></li>
                                                <li><p>Finds robust, globally optimal solutions</p></li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Translates deep insights into actionable strategies</p></li>
                                                <li><p>Maximizes KPIs while accounting for variable interdependencies</p></li>
                                            </ul>
                                            <p className='mb-0'>Achieve superior optimization through advanced AI techniques.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/nonlinear-optimization/8.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/nonlinear-optimization/9.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Multivariate Optimization: Holistic Media Strategy</h6>
                                        <div className="sub-content-div">
                                            <p>Our approach calculates Marginal ROIs across all media variables simultaneously, ensuring comprehensive optimization.</p>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Captures complex interactions and synergies between channels</p>
                                                </li>
                                                <li>
                                                    <p>Assesses each variable's true impact on overall outcome</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Identifies optimal allocation across all channels</p></li>
                                                <li><p>Detects cross-channel effects and cannibalization</p></li>
                                                <li><p>Maximizes overall ROI by balancing investments</p></li>
                                            </ul>
                                            <p className='mb-0'>This nuanced strategy leads to better-informed decisions and improved resource allocation across the entire media mix, avoiding siloed approaches.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Non-Linear Optimization: Capturing Complex Media Impacts</h6>
                                        <div className="sub-content-div">
                                            <p>Our optimizer leverages non-linear media impacts on KPIs and interconnected variable influences to calculate marginal ROI.</p>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Captures realistic, complex interactions</p>
                                                </li>
                                                <li>
                                                    <p>Represents diminishing returns accurately</p>
                                                </li>
                                                <li>
                                                    <p>Identifies synergies and saturation points</p>
                                                </li>
                                                <li>
                                                    <p>Optimizes across varying spend levels</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Accounts for how changes in one variable affect others</p></li>
                                                <li><p>Provides a holistic view of media performance</p></li>
                                                <li><p>Enables nuanced, effective optimization strategies</p></li>
                                            </ul>

                                            <p className='mb-0'>Result: More accurate media mix optimization driving better KPI outcomes.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/nonlinear-optimization/10.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/nonlinear-optimization/11.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Dynamic Platform: Adaptive Optimization</h6>
                                        <div className="sub-content-div">
                                            <p>Our Platform updates learnings monthly, ensuring optimization based on latest data patterns.</p>
                                            <p>Key benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Real-time responsiveness to market changes</p>
                                                </li>
                                                <li>
                                                    <p>Continuous improvement of strategies</p>
                                                </li>
                                                <li>
                                                    <p>Adaptation to seasonal trends and emerging patterns</p>
                                                </li>
                                                <li>
                                                    <p>Mitigation of outdated assumptions and model drift</p>
                                                </li>
                                            </ul>
                                            <p>This dynamic approach enables:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Agile decision-making</p></li>
                                                <li><p>Staying ahead of market shifts</p></li>
                                                <li><p>More accurate predictions</p></li>
                                                <li><p>Optimized outcomes</p></li>
                                            </ul>
                                            <p className='mb-0'>Regular updates create a virtuous cycle of learning and improvement, maintaining relevance in rapidly changing business environments.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NonlinearOptimization
