import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function CausalAi({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(5) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className="tab-content-div">
                            {<div className="banner-section">
                                {/* <div className="row">
                                    <div className="col-12">
                                        <h6 className='heading'>Causal AI - The Why</h6>
                                        <p>
                                        Our methods uncover causal relationships between variables. Rather than merely identifying correlations. <br />
                                        This helps you understand the true drivers of consumer behavior. Our Causal AI techniques uncover these underlying causal mechanisms Causal AI solves for this in several ways :

                                        </p>
                                    </div>
                                </div> */}
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <div className="row">
                                            <div className="col-12">
                                                {/* <h6 className='heading'>This is how it works</h6> */}
                                                <h6 className='heading'>Causal AI - The Why</h6>
                                                <p>
                                                Our methods uncover causal relationships between variables. <br /> 
                                                Rather than merely identifying correlations. <br />
                                                This helps you understand the true drivers of consumer behavior. <br /> 
                                                Our Causal AI techniques uncover these underlying causal mechanisms <br /> 
                                                Causal AI solves for this in several ways :

                                                </p>
                                            </div>
                                        </div>
                                        <h6 className='sub-heading'>1. Association: First Level of Pearl's Causal Hierarchy</h6>
                                        <div className="sub-content-div">
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Examines data patterns, answering "What if I see X?"</p>
                                                </li>
                                                <li>
                                                    <p>Uses Granger causality via neural networks</p>
                                                </li>
                                                <li>
                                                    <p>Identifies time-based relationships and potential cause-effect patterns</p>
                                                </li>
                                            </ul>
                                            <p>Key aspects:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Analyzes observed data for variable connections</p></li>
                                                <li><p>Doesn't prove causation, but hints at causal links</p></li>
                                                <li><p>Captures complex relationships in time-ordered data</p></li>
                                                <li><p>Identifies predictive features and time-lag structures</p></li>
                                            </ul>
                                            <p className='mb-0'>Remember: Association suggests but doesn't confirm causality, laying groundwork for advanced analysis in higher hierarchy levels.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/causal-ai/1.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/causal-ai/2.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Counterfactual Reasoning: Simulating "What-If" Scenarios</h6>
                                        <div className="sub-content-div">
                                            <p>Our key strength: estimating outcomes of hypothetical interventions.</p>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Predicts impact of different strategies on consumer behavior/sales</p>
                                                </li>
                                                <li>
                                                    <p>Understands causal relationships</p>
                                                </li>
                                                <li>
                                                    <p>Informs decision-making</p>
                                                </li>
                                                <li>
                                                    <p>Optimizes strategies without costly real-world trials</p>
                                                </li>
                                                <li>
                                                    <p>Identifies most effective interventions</p>
                                                </li>
                                                <li>
                                                    <p>Anticipates unintended consequences</p>
                                                </li>
                                            </ul>
                                            <p>This capability enhances:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Predictive power</p></li>
                                                <li><p>Strategic planning</p></li>
                                            </ul>
                                            <p className='mb-0'>By simulating scenarios, we gain valuable insights for optimizing marketing strategies and maximizing ROI without real-world risks.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Causal Inference: Uncovering True Drivers</h6>
                                        <div className="sub-content-div">
                                            <p>Our Causal AI techniques reveal genuine cause-effect relationships, not just correlations.</p>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Distinguishes associations from causal links</p>
                                                </li>
                                                <li>
                                                    <p>Identifies factors truly influencing outcomes</p>
                                                </li>
                                                <li>
                                                    <p>Provides deeper insights into consumer decision-making</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>More accurate predictions</p></li>
                                                <li><p>Effective interventions</p></li>
                                                <li><p>Answers "What if we change X?" and "Why did Y occur?"</p></li>
                                            </ul>
                                            <p>Applications:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Optimizing marketing strategies</p></li>
                                                <li><p>Enhancing product development</p></li>
                                                <li><p>Improving business decisions</p></li>
                                            </ul>
                                            <p className='mb-0'>We deliver actionable insights beyond surface correlations for impactful strategies.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/causal-ai/3.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/causal-ai/4.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Robust Causal AI: Reliable Across Scenarios</h6>
                                        <div className="sub-content-div">
                                            <p>Our Causal AI models offer superior robustness and transferability by capturing stable causal relationships.</p>
                                            <p>Key advantages:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Generalizes effectively to unseen scenarios</p>
                                                </li>
                                                <li>
                                                    <p>Maintains performance amid changing conditions</p>
                                                </li>
                                                <li>
                                                    <p>Provides reliable predictions despite shifts in data or preferences</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Consistent insights across diverse situations</p></li>
                                                <li><p>Guides confident decision-making</p></li>
                                                <li><p>Supports dependable long-term strategies</p></li>
                                                <li><p>Reduces risk of model failure</p></li>
                                            </ul>
                                            <p className='mb-0'>Trust our robust Causal AI for adaptable, reliable business intelligence in any environment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CausalAi
