import React, { useState } from 'react'
import "./mainscreen.scss"
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineHome, MdOutlineSend } from 'react-icons/md'
import Swal from 'sweetalert2';

const MainScreen = ({ activePage, setActivePage }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [formInfo, setFormInfo] = useState({ name: '', phoneNumber: '', email: '' })

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        // setActiveButton('dropdown');
    };

    const submit = async () => {
        let apiRes = await fetch('https://datapoem.ai/api/user-module/prospective-user/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formInfo)
        });
        let apiData = await apiRes.json();
        if (apiData.success === true) {
            Swal.fire({
                title: `Thanks for considering us.`,
                text: `Allow us a little time to get back to you.`,
                icon: 'success',
                showConfirmButton: false,
                heightAuto: false
            }).then(() => {
                setFormInfo({ name: '', phoneNumber: '', email: '' })
            })
        }
    }

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="lets-meet">
                        <div className="tabs">
                            <button
                                className={'btn btn-black home-btn text-white'}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    setActivePage(4)
                                }}
                            >
                                <MdOutlineHome size={24} />
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'granular')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'dataPoem')
                                    setActivePage(10)
                                }}
                            >
                                (Your MMM) <sup>Data Poem</sup>
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'shopper')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'shopper')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'longTerm')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'inFlight')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black text-white`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    <span>The AI Engine</span>
                                    <span
                                        className="arrow"
                                    >
                                        {isOpen ? <MdKeyboardArrowUp size={18} /> : <MdKeyboardArrowDown size={18} />}
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <div className="dropdown-item" onClick={() => {
                                            setIsOpen(false)
                                            localStorage.removeItem('activeTab');
                                            localStorage.setItem('activeTab', 'causalAi')
                                            setActivePage(10)
                                        }}>Causal AI</div>
                                        <div className="dropdown-item" onClick={() => {
                                            setIsOpen(false)
                                            localStorage.removeItem('activeTab');
                                            localStorage.setItem('activeTab', 'explainableAi')
                                            setActivePage(10)
                                        }}>Explainable AI</div>
                                        <div className="dropdown-item" onClick={() => {
                                            setIsOpen(false)
                                            localStorage.removeItem('activeTab');
                                            localStorage.setItem('activeTab', 'nonlinearOptimization')
                                            setActivePage(10)
                                        }}>Non Linear Optimization</div>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    // localStorage.setItem('activeTab', 'dataHub')
                                    setActivePage(6)
                                }}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row align-items-start g-0 w-100">
                                    <div className="col-12 content-div">
                                        <h3 className='heading'>
                                            The competitive advantages you get by <br />
                                            inviting us for coffee far outweigh the benefits <br />
                                            of not meeting us at all.
                                        </h3>
                                        <div className="row inputBox g-0">
                                            <div className="col-12 col-lg-3">
                                                <input type="text"
                                                    placeholder='Name'
                                                    value={formInfo.name}
                                                    onChange={(ev) => {
                                                        setFormInfo({ ...formInfo, name: ev.target.value })
                                                    }}
                                                />
                                                <input type="text"
                                                    placeholder='Phone Number'
                                                    value={formInfo.phoneNumber}
                                                    onChange={(ev) => {
                                                        setFormInfo({ ...formInfo, phoneNumber: ev.target.value })
                                                    }}
                                                />
                                                <input type="text"
                                                    placeholder='Email'
                                                    value={formInfo.email}
                                                    onChange={(ev) => {
                                                        setFormInfo({ ...formInfo, email: ev.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <button className='dive-btn' onClick={() => { submit() }}>Let's Meet <MdOutlineSend className='ms-2 icon' /></button>
                                    </div>
                                </div>
                                <div className="mt-auto footer-text w-100">
                                    <p className='mb-0'>33 Wood Ave S Ste 600, Iselin, <br />
                                        NJ 08830, United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
