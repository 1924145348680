import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function LongTermRoi({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(4) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className='tab-content-div'>
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='heading'>This is how it works</h6>
                                        <h6 className='sub-heading'>1. Causal AI for understanding long term impact of marketing</h6>
                                        <div className="sub-content-div">
                                            <p className='mb-3'>Traditional models often miss delayed effects and brand impact. Data POEM's advanced Causal AI with deep learning reveals true cause-and-effect relationships between marketing actions and long-term outcomes.</p>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Isolates genuine causal contributions of each touchpoint</p>
                                                </li>
                                                <li>
                                                    <p>Accounts for market conditions and competitor actions</p>
                                                </li>
                                                <li>
                                                    <p>Uncovers hidden patterns and long-term value drivers</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Reveals true long-term ROI</p></li>
                                                <li><p>Empowers data-driven decisions</p></li>
                                                <li><p>Optimizes immediate results and sustained growth</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/long-term-roi/13.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/long-term-roi/14.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Measure the total long-term effect and not by time periods - 1 year, 2 years etc</h6>
                                        <div className="sub-content-div">
                                            <p>Data POEM's causal AI revolutionizes ROI measurement:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Dynamically determines full marketing impact</p>
                                                </li>
                                                <li>
                                                    <p>Evaluates causal relationships until genuine decay detected</p>
                                                </li>
                                                <li>
                                                    <p>Adapts to varying decay rates across activities and channels</p>
                                                </li>
                                                <li>
                                                    <p>Captures persistent, delayed, and compound effects</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Reveals natural decay points, not arbitrary time limits</p></li>
                                                <li><p>Provides comprehensive view of true ROI</p></li>
                                                <li><p>Prevents undervaluing strategies with extended impact</p></li>
                                                <li><p>Delivers accurate insights for strategic decisions and resource allocation</p></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Understanding the long-term effect of every marketing tactic</h6>
                                        <div className="sub-content-div">
                                            <p>Causal AI excels in differentiating long-term effects across marketing channels. It recognizes that a display banner's impact differs from a search ad or brand video. The AI:</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Analyzes each channel's unique decay pattern</p>
                                                </li>
                                                <li>
                                                    <p>Quantifies immediate vs. delayed effects specific to each medium</p>
                                                </li>
                                                <li>
                                                    <p>Measures how channels interact and amplify each other</p>
                                                </li>
                                                <li>
                                                    <p>Accounts for channel-specific external factors</p>
                                                </li>
                                                <li>
                                                    <p>Adapts to changing consumer behaviors per channel</p>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>This granular approach reveals the true long-term value of each channel, enabling marketers to optimize their mix for both short-term results and sustained brand impact.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/long-term-roi/15.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/long-term-roi/16.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Measure the total effect of the Sponsorships like NFL</h6>
                                        <div className="sub-content-div">
                                            <p>Understanding long-term ROI is crucial for strategic investments like sponsorships and events, which yield benefits beyond immediate sales. Data POEM's long-term ROI solution captures the total value of these initiatives by:</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Measuring extended impact timelines</p>
                                                </li>
                                                <li>
                                                    <p>Quantifying effects on brand perception and customer behavior</p>
                                                </li>
                                                <li>
                                                    <p>Analyzing cross-channel amplification</p>
                                                </li>
                                                <li>
                                                    <p>Accounting for cumulative benefits from repeated exposure</p>
                                                </li>
                                                <li>
                                                    <p>Isolating sponsorship impact from other activities</p>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>This comprehensive approach reveals the full spectrum of sponsorship value, enabling informed decisions on high-stakes investments and optimal resource allocation for maximum long-term impact</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LongTermRoi
