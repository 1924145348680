import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

const faqsObj = {
    tab1: [
        {
            title: 'I already use MMMs. Does Data Poem replace them?',
            content: (
                <div>
                    <p className='faq-para'>
                        Absolutely not! Think of your MMMs as the essential instruments in your marketing cockpit – providing a solid foundation for understanding your marketing performance. Data Poem acts as your AI co-pilot, seamlessly integrating with your current setup. Together, you can achieve a more holistic view of your marketing efforts.
                    </p>
                </div>
            ),
        },
        {
            title: "What can Data Poem offer that MMMs can't?",
            content: (
                <div>
                    <p className="faq-para">
                        While MMMs are great at providing high-level insights, Data Poem delves deeper. Our AI analyzes vast amounts of data from various sources, revealing hidden interactions between channels and predicting long-term ROI. This allows you to optimize campaigns in real-time and unlock hidden potential within your existing marketing mix.
                    </p>
                </div>
            ),
        },
        {
            title: 'How does Data Poem work with my existing data sources?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem is designed to seamlessly integrate with your existing data infrastructure. We can connect to various data sources, including marketing platforms, CRMs, and sales data. This allows you to gain a unified view of your marketing performance without the need for complex data manipulation.
                    </p>
                </div>
            ),
        },
        {
            title: 'Is Data Poem easy to use?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem is designed for marketers of all technical backgrounds. Our intuitive interface provides easy access to actionable insights. However, we also offer dedicated customer support to ensure you get the most out of the platform.
                    </p>
                </div>
            ),
        },
        {
            title: 'How can I learn more about Data Poem?',
            content: (
                <div>
                    <p className="faq-para">
                        You can also contact our team for a personalized demo or to discuss your specific marketing challenges.
                    </p>
                </div>
            ),
        },
    ],
    tab2: [
        {
            title: 'What types of data sources can Data Poem integrate with?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem is designed to be a versatile data hub, able to connect with a wide range of marketing data sources. This includes:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p className="faq-para">
                                <span>Marketing platforms:</span> Social media platforms, email marketing tools, search engine advertising platforms, marketing automation software, etc.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Customer Relationship Management (CRM) systems:</span> Track interactions and customer data points within your CRM platform.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Sales data:</span> Salesforce data, point-of-sale systems, and other sources of sales information can be integrated for a more comprehensive view.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Website analytics:</span> Data from website analytics tools like Google Analytics can be integrated to understand online customer behavior.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Web server logs:</span> Detailed server log data can be incorporated for even deeper insights into user activity on your website.
                            </p>
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            title: "How does Data Poem handle data quality issues?",
            content: (
                <div>
                    <p className="faq-para">
                        Data quality is crucial for accurate analysis. Data Poem employs a multi-step data cleaning process to ensure the integrity of your information. This includes:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p className="faq-para">
                                <span>Data validation:</span> Checking for inconsistencies, missing values, and other errors.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Data transformation:</span> Formatting data into a consistent structure for analysis.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Outlier detection:</span> Identifying and addressing potential outliers that could skew results.
                            </p>
                        </li>
                    </ul>
                    <p className='faq-para'>Our data quality checks help ensure your analyses are based on reliable information, leading to more accurate and actionable insights.</p>
                </div>
            ),
        },
        {
            title: 'Can Data Poem be used for specific marketing channels (e.g., social media, email)?',
            content: (
                <div>
                    <p className="faq-para">
                        Absolutely! Data Poem can be used to analyze the performance of individual marketing channels and identify areas for improvement. You can gain insights into:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p className="faq-para">
                                <span>Social media:</span> Track engagement metrics, analyze audience demographics, and measure the effectiveness of social media campaigns.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Email marketing:</span> Identify open rates, click-through rates, and conversion rates to optimize your email campaigns for better results.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Search engine advertising (SEA):</span> Analyze keyword performance, optimize bidding strategies, and measure the return on ad spend (ROAS) for your SEA campaigns.
                            </p>
                        </li>
                    </ul>
                    <p className='faq-para'>Data Poem lets you drill down into specific channels to understand their unique contribution to your overall marketing mix.</p>
                </div>
            ),
        },
        {
            title: 'How does Data Poem measure the impact of non-traditional marketing efforts (e.g., sponsorships)?',
            content: (
                <div>
                    <p className="faq-para">
                        Measuring the impact of non-traditional marketing efforts can be challenging. Data Poem tackles this by using a combination of techniques:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p className="faq-para">
                                <span>Attribution modeling:</span> Data Poem's advanced attribution models can statistically estimate the contribution of sponsorships and other non-traditional efforts to overall marketing success.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Customizable dashboards:</span> Create dashboards that track relevant metrics associated with sponsorships, such as brand mentions, social media engagement, and website traffic spikes coinciding with sponsorship events.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>A/B testing:</span> Run A/B tests to compare the effectiveness of campaigns with and without sponsorships to isolate the impact of these efforts.
                            </p>
                        </li>
                    </ul>
                    <p className='faq-para'>By combining these methods, Data Poem provides valuable insights into the effectiveness of your non-traditional marketing activities.</p>
                </div>
            ),
        },
        {
            title: 'Can Data Poem be used to predict customer behavior?',
            content: (
                <div>
                    <p className="faq-para">
                        Yes, Data Poem can be used to predict customer behavior with a certain degree of accuracy. Our AI utilizes historical data and customer interactions to identify patterns and trends.
                    </p>
                    <p className="faq-para">
                        Based on this analysis, Data Poem can:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p className="faq-para">
                                <span>Predict customer churn:</span> Identify customers at risk of leaving and recommend targeted retention campaigns.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Recommend products:</span> Suggest products to customers based on their past purchases and browsing behavior.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Personalize marketing messages:</span> Personalize marketing communications to increase relevance and engagement.
                            </p>
                        </li>
                    </ul>
                </div>
            ),
        },
    ],
    tab3: [
        {
            title: 'How long does it take to implement Data Poem?',
            content: (
                <div>
                    <p className="faq-para">
                        The implementation timeframe for Data Poem can vary depending on the complexity of your existing data infrastructure and the desired level of customization. However, in most cases, Data Poem can be implemented in a matter of weeks. Here's a breakdown of the typical process:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p className="faq-para">
                                <span>Initial consultation:</span> Our team will work with you to understand your specific needs and goals.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Data source identification:</span> We'll identify the relevant data sources within your organization.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Data connection setup:</span> We'll establish secure connections to your data sources.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Data validation and cleaning:</span> We'll ensure your data is clean and ready for analysis.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Model training and configuration:</span> We'll tailor Data Poem's AI models to your specific marketing mix.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>User training and onboarding:</span> Our team will provide training to your marketing team on how to use Data Poem effectively.
                            </p>
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            title: 'What level of technical expertise is needed to use Data Poem?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem is designed for marketers of all technical backgrounds. Our user interface is intuitive and easy to navigate, allowing you to access insights and reports without needing extensive technical knowledge. However, for advanced users, we offer the ability to customize dashboards and delve deeper into the data. Our dedicated support team is also available to assist you with any questions you may have.
                    </p>
                </div>
            ),
        },
        {
            title: 'Does Data Poem require any additional software or hardware?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem is a cloud-based solution, so there is no need for you to install any additional software or hardware on your own systems. This eliminates the need for upfront investment in IT infrastructure and simplifies the implementation process.
                    </p>
                </div>
            ),
        },
        {
            title: 'How does Data Poem integrate with my existing marketing dashboards and reporting tools?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem integrates seamlessly with most popular marketing dashboards and reporting tools. This allows you to incorporate Data Poem's insights alongside other marketing data for a unified view of your marketing performance. We offer pre-built connectors for popular platforms, and our API allows for custom integrations with other tools.
                    </p>
                </div>
            ),
        },
        {
            title: 'What kind of ongoing support does Data Poem offer?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem offers a range of ongoing support options to ensure you get the most out of the platform. This includes:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p className="faq-para">
                                <span>Dedicated customer success manager:</span> You'll have a dedicated point of contact within our team to answer your questions and provide ongoing guidance.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Online knowledge base:</span> Our comprehensive knowledge base provides access to helpful articles, tutorials, and FAQs.
                            </p>
                        </li>
                        <li>
                            <p className="faq-para">
                                <span>Regular webinars and training sessions:</span> We offer ongoing webinars and training sessions to help you stay up-to-date on the latest features and functionalities of Data Poem.
                            </p>
                        </li>
                    </ul>
                    <p className="faq-para">
                        Our support team is committed to helping you maximize the value of Data Poem for your marketing efforts.
                    </p>
                </div>
            ),
        }
    ],
    tab4: [
        {
            title: 'How does Data Poem handle my data security and privacy?',
            content: (
                <div>
                    <p className="faq-para">
                        Data security and privacy are top priorities for Data Poem. We take the following measures to ensure your data is safe and secure:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p>
                                <span>Secure cloud infrastructure:</span> Data Poem utilizes a secure and reliable cloud infrastructure that meets industry-standard security protocols.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span>Data encryption:</span> All data at rest and in transit is encrypted to protect against unauthorized access.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span>Access controls:</span> We implement strict access controls to ensure only authorized personnel can access your data.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Compliance with regulations:</span> Data Poem adheres to relevant data privacy regulations such as GDPR and CCPA.
                            </p>
                        </li>
                    </ul>
                    <p className="faq-para">
                        We understand the importance of safeguarding your sensitive marketing data, and we take all necessary steps to ensure its security and privacy.
                    </p>
                </div>
            ),
        },
        {
            title: 'Does Data Poem comply with data privacy regulations (e.g., GDPR)?',
            content: (
                <div>
                    <p className="faq-para">
                        Yes, Data Poem is designed to comply with relevant data privacy regulations such as the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). We offer features that allow you to manage user consent and data deletion requests in accordance with these regulations. Our team stays up-to-date on evolving data privacy regulations to ensure ongoing compliance.
                    </p>
                </div>
            ),
        }
    ],
    tab5: [
        {
            title: 'How can I measure the ROI of using Data Poem?',
            content: (
                <div>
                    <p className="faq-para">
                        Data Poem provides several ways to measure the return on investment (ROI) you can expect:
                    </p>
                    <ul className="list-style">
                        <li>
                            <p>
                                <span>Increased marketing efficiency:</span> Data Poem helps you optimize campaigns and allocate resources more effectively, leading to cost savings and improved marketing ROI.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span>Improved campaign performance:</span> By gaining deeper insights into customer behavior and interactions, you can optimize your campaigns for better results, leading to higher conversion rates and increased revenue.
                            </p>
                        </li>

                        <li>
                            <p>
                                <span>Long-term ROI visibility:</span> Data Poem's predictive capabilities allow you to see the long-term impact of your marketing efforts, helping you make strategic decisions for sustainable ROI growth.
                            </p>
                        </li>
                    </ul>
                    <p className="faq-para">
                        We recommend tracking relevant metrics such as conversion rates, cost per acquisition (CPA), and customer lifetime value (CLTV) to quantify the ROI of Data Poem for your business.
                    </p>
                </div>
            ),
        },
    ]
}

const AccordionItem = ({ title, content, isOpen, onClick }) => (
    <div className={`faq-card ${isOpen ? 'expanded' : ''}`} onClick={onClick}>
        <div className="faq-card-header">
            <h6 className="faq-card-content mb-0">{title}</h6>
            {!isOpen && <MdKeyboardArrowRight />}
            {isOpen && <MdKeyboardArrowDown />}
        </div>
        {isOpen && <div className="faq-answer">{content}</div>}

    </div>
);
function FaqAccordion() {
    const [openIndex, setOpenIndex] = useState(null);
    const [openedTab, setOpenedTab] = useState('tab1');

    const faqs = faqsObj[openedTab];

    const handleAccordionClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => {
        return () => {
            setOpenIndex(null);
            setOpenedTab('tab1')
        }
    }, [])

    return (
        <div className='faq-page'>
            <h6 className='heading'>FAQs</h6>
            <div className='faq-tabs'>
                <div className={`faq-tab tab1 ${openedTab === 'tab1' ? 'active' : ''}`} onClick={() => { setOpenedTab('tab1'); setOpenIndex(null); }}>General Functionality</div>
                <div className={`faq-tab tab2 ${openedTab === 'tab2' ? 'active' : ''}`} onClick={() => { setOpenedTab('tab2'); setOpenIndex(null); }}>Data & Analytics</div>
                <div className={`faq-tab tab3 ${openedTab === 'tab3' ? 'active' : ''}`} onClick={() => { setOpenedTab('tab3'); setOpenIndex(null); }}>Implementation & Integration</div>
                <div className={`faq-tab tab4 ${openedTab === 'tab4' ? 'active' : ''}`} onClick={() => { setOpenedTab('tab4'); setOpenIndex(null); }}>Security & Privacy</div>
                <div className={`faq-tab tab5 ${openedTab === 'tab5' ? 'active' : ''}`} onClick={() => { setOpenedTab('tab5'); setOpenIndex(null); }}>Benefits & ROI</div>
            </div>
            {faqs && faqs.length > 0 && faqs.map((faq, index) => (
                <AccordionItem
                    key={index}
                    title={faq.title}
                    content={faq.content}
                    isOpen={openIndex === index}
                    onClick={() => handleAccordionClick(index)}
                />
            ))}
        </div>
    )
}

export default FaqAccordion
