import React from 'react'
import "./screen4.scss"
import "./responsive.scss"
import MainScreen from './mainscreen/mainscreen';

function Screen4({activePage, setActivePage}) {

  return (
    <div className={'full-screen screen4'} key="screen1">
      <div className="bg-screen"></div>
      <div className="mask"></div>
      <div className="ellipse"></div>
      <div className="content bootstrap-content">
        <MainScreen activePage={activePage} setActivePage={setActivePage} />
      </div>
      <div className="ai-bottom-left">
        <img src={require("../../assets/images/ai-rocket.png")} alt="" />
        <img src={require("../../assets/images/ai-pulse.png")} alt="" className='ai-pulse' />
      </div>
    </div>
  )
}

export default Screen4