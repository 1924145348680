import React, { useEffect, useRef, useState } from 'react'
import "./screen1.scss"
import backgroundVideo from "../../assets/home-video-bg.mp4"

const rotateArr = ['QSR', 'Hair Care', 'Nutrition', 'Snacks', 'Auto', 'Food', 'D2C'
    , 'BFSI'
]

function Screen1({ activePage, setActivePage }) {
    const [activeRotateIndex, setActiveRotateIndex] = useState(0);
    const videoRef = useRef(null);
    const [playRate, setPlayRate] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = playRate;
        }
    }, [playRate]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveRotateIndex((prevIndex) => (prevIndex === rotateArr.length - 1 ? 0 : prevIndex + 1));
        }, 2000);

        return () => {
            clearInterval(interval)
        };
    }, [activePage]);

    return (
        <div className={'full-screen screen1'} key="screen1">
            {/* {!isLoaded &&  */}
            <div className="bg-screen"></div>
            {/* } */}
            {/* <video
                className={isLoaded ? "background-video" : "d-none"}
                ref={videoRef}
                src={backgroundVideo}
                onPlay={() => { setPlayRate(0.5) }}
                onLoadedData={() => { setIsLoaded(true) }}
                autoPlay loop muted>
            </video> */}
            <div className="ellipse"></div>
            <div className="content">
                <h3 className="text">The Future of Marketing Analytics is <br /> Connected Intelligence. </h3>
                <h3 className="text">Everyone knows it. But no one <br /> knows how to connect. </h3>
                <h3 className="text"><span>WE ARE CONNECTING IT ALREADY</span></h3>
                <h3 className="text">We analyze over <span>$2.2bn</span> spends monthly <br /> for over <span>30</span> brands.</h3>
                <h3 className="text flex-align-center">We work with leaders in
                    <div className="w-200">
                        <div className="rotating-container">
                            {rotateArr.map((text, index) => (
                                <div
                                    key={index}
                                    className={`rotating-text ${index === activeRotateIndex ? 'enter' : ''} ${index === (activeRotateIndex === 0 ? rotateArr.length - 1 : activeRotateIndex - 1) ? 'exit' : ''}`}
                                >
                                    {text}
                                </div>
                            ))}
                        </div>
                    </div>
                </h3>
            </div>
            {/* <div className="bottom-center" onClick={() => { setActivePage(1) }}></div> */}
        </div>
    )
}

export default Screen1