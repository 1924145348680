import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function AgileMonthlyRoi({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(4) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className='tab-content-div'>
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='heading'>This is how it works</h6>
                                        <h6 className='sub-heading'>1. Monthly ROI with 2-week lag</h6>
                                        <div className="sub-content-div">
                                            <p>Our new paradigm offers:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Comprehensive view across all channels</p>
                                                </li>
                                                <li>
                                                    <p>Detailed audience segment insights</p>
                                                </li>
                                                <li>
                                                    <p>Consolidated broad and granular data</p>
                                                </li>

                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Reveals intricate patterns and audience-specific trends</p></li>
                                                <li><p>Enables precise optimization and personalized strategies</p></li>
                                                <li><p>Smarter budget allocation</p></li>
                                                <li><p>Improved targeting</p></li>
                                                <li><p>Higher overall ROI</p></li>
                                            </ul>
                                            <p className='mb-0'>This powerful approach empowers marketers to make swift, confident, data-driven decisions, driving business growth through effective, audience-centric marketing strategies.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/agile-monthly-roi/17.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/agile-monthly-roi/18.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Revolutionary transfer Learning for Incremental training</h6>
                                        <div className="sub-content-div">
                                            <p>To deliver monthly ROI models with just a 2-week lag, our engine leverages transfer learning techniques, which allow for efficient model adaptation and fine-tuning using the most recent data.</p>
                                            <p>The pre-trained causal models, which capture the general causal relationships and dynamics, serve as the starting point for transfer learning.</p>
                                            <p className='mb-0'>As new data becomes available, our engine performs efficient fine-tuning of the causal models, updating the parameters and causal strengths.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Continuous Learning and Model Refinement</h6>
                                        <div className="sub-content-div">
                                            <p>As more data becomes available over time, our engine continuously refines and updates the causal models. This ensures they remain accurate and relevant in the face of evolving market dynamics.</p>
                                            <p className='mb-0'>Our incremental structure learning enables our engine to adapt the causal models in a streaming fashion, incorporating new data and adjusting the causal structures as needed.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/agile-monthly-roi/19.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/agile-monthly-roi/20.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Counterfactual Inference and Scenario Simulation</h6>
                                        <div className="sub-content-div">
                                            <p>With counterfactual inference, you can evaluate the impact of hypothetical interventions or changes in marketing strategies.</p>
                                            <p>We simulate alternative scenarios by intervening on specific variables in the causal model and propagating the effects through the causal pathways.</p>
                                            <p className='mb-0'>By simulating multiple scenarios and comparing predicted outcomes, our engine can recommend optimal marketing strategies.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgileMonthlyRoi
