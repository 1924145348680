import React, { useRef, useState, useEffect } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';
import { IoVolumeHigh, IoVolumeMute } from "react-icons/io5";
import { SlSizeActual, SlSizeFullscreen } from "react-icons/sl";

const VideoPlayer = ({activePage, setActivePage}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  // const [curValue, setCurValue] = useState(0);

  const handleFullscreen = () => {
    // if (videoRef.current.requestFullscreen) {
    //   videoRef.current.requestFullscreen();
    // } else if (videoRef.current.webkitRequestFullscreen) { /* Safari */
    //   videoRef.current.webkitRequestFullscreen();
    // } else if (videoRef.current.msRequestFullscreen) { /* IE11 */
    //   videoRef.current.msRequestFullscreen();
    // }
    if(activePage === 11){
      setActivePage(7)
    }else{
      // localStorage.setItem('duration', duration);
      // localStorage.setItem('currentTime', currentTime);
      // localStorage.setItem('isPlaying', setIsPlaying);
      setActivePage(11);
    }
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    // var value = duration === 0 ? 0 : (videoRef.current.currentTime-0)/(duration-0)*100;
    // setCurValue(value)
  };

  const handleSliderChange = (event) => {
    videoRef.current.currentTime = event.target.value;
    setCurrentTime(event.target.value);
  };

  // useEffect(() => {
  //   let ct = localStorage.getItem('duration');
  //   let dt = +localStorage.getItem('currentTime');
  //   let pl = localStorage.getItem('isPlaying');
  //   setDuration(dt);
  //   if(videoRef.current){
  //     videoRef.current.currentTime = ct;
  //     if(pl == true){
  //       videoRef.current.play();
  //     }else{
  //       videoRef.current.pause();
  //     }
  //   }
  //   setCurrentTime(ct);
  //   setIsPlaying(pl)
  // }, [])
  
  useEffect(() => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  }, [videoRef.current?.duration]);

  return (
    <div className='videoPlayer' style={styles.container}>
      <div style={styles.iconContainer}>
        {activePage === 11 && <SlSizeActual style={styles.icon} onClick={handleFullscreen} />}
        {activePage === 7 && <SlSizeFullscreen style={styles.icon} onClick={handleFullscreen} />}
        <div style={styles.rightIcons}>
          {isMuted && <IoVolumeMute style={styles.icon} onClick={handleMute} />}
          {!isMuted && <IoVolumeHigh style={styles.icon} onClick={handleMute} />}
          {/* <MdSubtitles style={styles.icon} /> */}
        </div>
      </div>
      <video
        onClick={() => {handlePlayPause()}}
        ref={videoRef}
        src={require('../../../assets/unbox-vid-1.mp4')}
        style={styles.video}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => setDuration(videoRef.current.duration)}
        onEnded={() => {
          setCurrentTime(0); 
          setDuration(videoRef.current.duration);
          setIsPlaying(false)
        }}
        controls={false}
      >
        <source src="your-video-url.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={styles.controls}>
        <button onClick={handlePlayPause} style={styles.playPauseButton}>
          {isPlaying ? <FaPause style={styles.playIcon} /> : <FaPlay style={styles.playIcon} />}
        </button>
        <input
          id="rangeInput"
          type="range"
          min="0"
          max={duration}
          value={currentTime}
          onChange={handleSliderChange}
          style={styles.slider}
          className="slider"
          muted={isMuted}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
  },
  video: {
    width: '100%',
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  icon: {
    cursor: 'pointer',
    fontSize: '20px',
    color: '#fff',
    zIndex: 1,
    marginBottom: '10px'
  },
  playIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    color: '#fff',
    zIndex: 1
  },
  rightIcons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    bottom: '10px',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  playPauseButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  slider: {
    width: '100%',
    flex: 1,
    marginLeft: '10px',
    marginTop: '5px',
    height: '5px'
  },
};

export default VideoPlayer;