import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md'
import FaqAccordion from '../faq-accordion/FaqAccordion'

function GranularRoiPages({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(4) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        </div>
                        <div className='tab-content-div'>
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        {/* <h6 className='heading'>This is how it works</h6> */}
                                        <h6 className='heading'>This is how it works</h6>
                                        <h6 className='sub-heading'>1. 6-layer Deep Measurement</h6>
                                        <div className="sub-content-div">
                                            <p>Our granular ROI product delivers unparalleled insights through a 6-layer deep measurement approach:</p>
                                            <p>1. Brand level: Overall brand performance and equity impact</p>
                                            <p>2. Campaign level: Effectiveness of specific marketing initiatives</p>
                                            <p>3. Media level: Comparison across different media types (e.g., TV, digital, print)</p>
                                            <p>4. Channel level: Performance within each media (e.g., social platforms, search engines)</p>
                                            <p>5. Platform level: Granular insights on specific platforms (e.g., Facebook, Google)</p>
                                            <p>6. Audience level: ROI across different customer segments</p>
                                            <p>This multi-layered approach enables the identification of synergies and inefficiencies.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/granular-roi/5.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/granular-roi/6.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Causal AI & Deep Learning for Granular Attribution</h6>
                                        <div className="sub-content-div">
                                            <p>Account for attribution at multiple levels (campaign, channel audience) :</p>
                                            <p>1. Isolate individual marketing effects: Precisely measure impact of specific tactics and channels</p>
                                            <p>2. Handle complex non-linear relationships: Capture nuanced interactions between marketing elements</p>
                                            <p>3. Account for time-lagged effects: Understand both immediate and long-term impacts</p>
                                            <p>4. Adaptive learning: Continuously update models based on new data and market changes</p>
                                            <p>5. Counterfactual analysis: Simulate "what-if" scenarios for strategic planning</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Actionable Insights from Granular ROI</h6>
                                        <div className="sub-content-div">
                                            <p>Precise budget allocation: Optimize spend across channels, platforms, and campaigns</p>
                                            <p>Audience targeting: Tailor strategies to most responsive segments</p>
                                            <p>Creative optimization: Identify high-performing content elements</p>
                                            <p>Timing optimization: Determine ideal timing and frequency for maximum impact</p>
                                            <p>Cross-channel synergies: Leverage insights on channel interactions for integrated strategies</p>
                                            <p>Campaign refinement: Continuously improve based on granular performance data</p>
                                            <p>ROI forecasting: Predict outcomes of potential marketing scenarios</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/granular-roi/7.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/granular-roi/8.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Improved ROI</h6>
                                        <div className="sub-content-div">
                                            <p>Identify high-performing platforms and audiences, leading to superior resource allocation and increased overall ROI.</p>
                                            <p>Precision targeting: Focus resources on most responsive segments</p>
                                            <p>Channel optimization: Allocate budget to best-performing channels</p>
                                            <p>Tactic refinement: Double down on strategies yielding highest returns</p>
                                            <p>Waste reduction: Eliminate or reduce low-impact activities</p>
                                            <p>Agile decision-making: Quickly adapt to performance insights</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GranularRoiPages
