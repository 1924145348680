import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function InflightOptimization({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(4) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className='tab-content-div'>
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='heading'>This is how it works</h6>
                                        <h6 className='sub-heading'>1. Real-time Data Ingestion and Processing</h6>
                                        <div className="sub-content-div">
                                            <p>We leverage scalable data pipelines and distribute computing frameworks to handle the high volume and velocity of marketing data streams.</p>
                                            <p>We then use steam processing, event-driven architectures, and micro-batching to continuously ingest and process data.</p>
                                            <p className='mb-0'>We then apply advanced data preprocessing and feature engineering techniques to transform raw data into meaningful representations suitable for downstream machine learning models.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/inflight/21.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/inflight/22.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Non-linear Modeling and Adaptive Optimization</h6>
                                        <div className="sub-content-div">
                                            <p>We use Deep neural network architectures, to automatically learn hierarchical representations and temporal dynamics from high-dimensional marketing data.</p>
                                            <p>Online learning and transfer learning techniques enable these deep learning models to continuously adapt and fine-tune their parameters as new data becomes available.</p>
                                            <p className='mb-0'>This ensures that the optimization recommendations remain relevant and accurate in dynamic environments.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Interpretable Insights and Counterfactual Reasoning</h6>
                                        <div className="sub-content-div">
                                            <p>Causal inference and causal effect estimation allow our engine to simulate the potential impact of interventions or changes to marketing strategies during active campaigns.</p>
                                            <p>Our engine not only provides accurate predictions but also interpretable explanations for its recommendations.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/inflight/23.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/inflight/24.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Closed-loop Optimization and Decision Support</h6>
                                        <div className="sub-content-div">
                                            <p>We employ advanced decision support techniques like multi-objective optimization, to automatically discover optimal marketing strategies that balance multiple objectives.</p>
                                            <p>Human-in-the-loop interfaces and visualization tools enable you to interact with the optimization engine, provide feedback and make informed decisions.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InflightOptimization
