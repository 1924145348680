import React, { useEffect, useState } from 'react'
import "./mainscreen.scss"
import "./responsive.scss"
import { MdChevronRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineHome } from 'react-icons/md'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const MainScreen = ({ activePage, setActivePage }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [canSlide, setCanSlide] = useState(true);
    const [activeCarousel, setActiveCarousel] = useState(1);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        let slideInterval;
        (async () => {
            slideInterval = setInterval(() => {
                if(canSlide){
                    setActiveCarousel(prevActiveCarousel => 
                        prevActiveCarousel + 1 === 4 ? 1 : prevActiveCarousel + 1
                    );
                }
            }, 2000);
        })()
    
        return () => {
            clearInterval(slideInterval);
        };
    }, [canSlide])

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="ai-engine">
                        <div className="tabs">
                            <button
                                className={'btn btn-black home-btn text-white'}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    setActivePage(4)
                                }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'granular')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'dataPoem')
                                    setActivePage(10)
                                }}
                            >
                                (Your MMM) <sup>Data Poem</sup>
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'shopper')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'shopper')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'longTerm')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'inFlight')
                                    setActivePage(10)
                                }}
                            // onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black text-white active`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    <span>The AI Engine</span>
                                    <span
                                        className="arrow"
                                    >
                                        {isOpen ? <MdKeyboardArrowUp size={18} /> : <MdKeyboardArrowDown size={18} />}
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <div className="dropdown-item" onClick={() => {
                                            setIsOpen(false)
                                            localStorage.removeItem('activeTab');
                                            localStorage.setItem('activeTab', 'causalAi')
                                            setActivePage(10)
                                        }}>Causal AI</div>
                                        <div className="dropdown-item" onClick={() => {
                                            setIsOpen(false)
                                            localStorage.removeItem('activeTab');
                                            localStorage.setItem('activeTab', 'explainableAi')
                                            setActivePage(10)
                                        }}>Explainable AI</div>
                                        <div className="dropdown-item" onClick={() => {
                                            setIsOpen(false)
                                            localStorage.removeItem('activeTab');
                                            localStorage.setItem('activeTab', 'nonlinearOptimization')
                                            setActivePage(10)
                                        }}>Non Linear Optimization</div>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    // localStorage.setItem('activeTab', 'dataHub')
                                    setActivePage(6)
                                }}
                            >
                                The Data Hub
                            </button>
                        </div>
                            <div className='tab-content-div'>
                                <div className="banner-section">
                                    <div className="row gx-0 align-items-start justify-content-end">
                                        <div className="col-12 w-lg-70 content-div ai-700-div">
                                            <h6 className='heading'>The AI Engine</h6>
                                            <p className='heading-para'>
                                                {/* Once we have the data, we synthesize it using cutting-edge technologies, borrowed from the future. <br /> 
                                                These technologies transcend the limitations of MMMs, Bayesian models in use currently.  <br /> 
                                                Our tech stack is a game changer in utilizing neural networks. 
                                                What’s more there is non-linear interconnected optimization */}
                                                Leveraging advanced deep learning and causal inference, our engine synthesizes data to <br /> 
                                                overcome the constraints of traditional MMMs and Bayesian models. We employ cutting-edge <br /> 
                                                neural networks, a revolutionary approach in the industry. Additionally, our engine excels in <br /> 
                                                multivariate non-linear optimization, a key differentiator in maximizing results.
                                            </p>
                                            <h6 className='sub-heading'>3 things that make it the most advanced :</h6>
                                            <div className="sub-content-div">
                                                {/* DIVE DEEP LIKE A NERD */}
                                                <div className={`carousel ${activeCarousel === 1 ? 'left' : (activeCarousel === 2 ? 'center' : 'right')}`} onMouseEnter={() => {setCanSlide(false)}} onMouseLeave={() => {setCanSlide(true)}}>
                                                    <div className={`carousel-slide ${activeCarousel === 1 ? 'active-slide' : ''}`}>
                                                        {/* {activeCarousel !== 1 && <div className='leftActivate'><FaChevronRight className='pointer' onClick={() => {setActiveCarousel(2)}} color='#fff' /></div>} */}
                                                        {activeCarousel !== 1 && <div className='rightActivate'><FaChevronLeft className='pointer' onClick={() => {setActiveCarousel(1)}} color='#fff' /></div>}
                                                        <div className={`ai-card ${activeCarousel !== 1 ? 'blur' : ''}`}>
                                                            <p className='text-white mb-0 left-float-num' style={{verticalAlign: 'end'}}>1 A uniquely constructed AI<br />
                                                            {/* &nbsp; */}
                                                            unlike anyone has ever attempted before
                                                            </p>
                                                            <p className='mb-0'>&nbsp;</p>
                                                            {/* <div className="divider"></div> */}
                                                            <p className='text-white sub-para mb-0'>We've unleashed the power of deep learning with neural <br /> networks for data-driven decision-making by harnessing <br /> Causal AI and Explainable AI.</p>
                                                            {/* <p className='mb-0'>&nbsp;</p> */}
                                                            <div className='btn-container'>
                                                                <button className='dive-btn'
                                                                    onClick={() => {
                                                                        localStorage.removeItem('activeTab');
                                                                        localStorage.setItem('activeTab', 'causalAi')
                                                                        setActivePage(10)
                                                                    }}
                                                                >Dive Deep into Causal AI <MdChevronRight /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="carousel-slide">
                                                        {activeCarousel !== 2 && <div className='leftActivate'><FaChevronRight className='pointer' onClick={() => {setActiveCarousel(2)}} color='#fff' /></div>}
                                                        {activeCarousel !== 2 && <div className='rightActivate'><FaChevronLeft className='pointer' onClick={() => {setActiveCarousel(2)}} color='#fff' /></div>}
                                                        <div className={`ai-card ${activeCarousel !== 2 ? 'blur' : ''}`}>
                                                            <p className='text-white mb-0 left-float-num'>2 AI-based Interconnected Optimization <br /> for 90% forecasting accuracy</p>
                                                            <p className='mb-0'>&nbsp;</p>
                                                            {/* <div className="divider"></div> */}
                                                            <p className='text-white sub-para mb-0'>Marketing is all about complex, non-linear relationships between various <br /> factors and desired outcomes. We optimize plans along intricate <br /> dependencies/ synergies.</p>
                                                            {/* <p className='mb-0'>&nbsp;</p> */}
                                                            <div className='btn-container'>
                                                                <button className='dive-btn'
                                                                    onClick={() => {
                                                                        localStorage.removeItem('activeTab');
                                                                        localStorage.setItem('activeTab', 'explainableAi')
                                                                        setActivePage(10)
                                                                    }}
                                                                >Dive Deep into Explainable AI <MdChevronRight /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="carousel-slide">
                                                        {activeCarousel !== 3 && <div className='leftActivate'><FaChevronRight className='pointer' onClick={() => {setActiveCarousel(3)}} color='#fff' /></div>}
                                                        <div className={`ai-card ${activeCarousel !== 3 ? 'blur' : ''}`}>
                                                            <p className='text-white mb-0 left-float-num'>3 Liberating ROI Optimization from the curse of linear world: Saturation curves</p>
                                                            {/* <p className='text-white'>Liberating ROI Optimization from the curse of linear world: Saturation curves</p> */}
                                                            {/* <div className="divider"></div> */}
                                                            <p className='mb-0'>&nbsp;</p>
                                                            {/* <p className='text-white sub-para mb-0'>The reign of saturation curves is over. These outdated tools, with their simplistic linear view, fail to capture the complex, interconnected reality of modern marketing. Embrace Data POEM's revolutionary approach, unlocking the true potential of your marketing efforts in our dynamic, nonlinear world.</p> */}
                                                            <p className='text-white sub-para mb-0'>The reign of saturation curves is over. These outdated tools, with their simplistic linear view, fail to capture the complex, interconnected reality of modern marketing. Data POEM unleashes marketing's true potential.</p>
                                                            {/* <p className='text-white sub-para mb-0'>It's a unified growth planning tool. Measure the total incremental impact of interconnected business drivers: brand marketing, shopper marketing, commerce marketing, trade promotions, and more.  Thanks to 90%+ forecasting accuracy with non-linear multivariate forecasting and optimization.</p> */}
                                                            <button className='dive-btn'
                                                                onClick={() => {
                                                                    localStorage.removeItem('activeTab');
                                                                    localStorage.setItem('activeTab', 'nonlinearOptimization')
                                                                    setActivePage(10)
                                                                }}
                                                            >Dive Deep into Non-Linear Optimization <MdChevronRight /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="carousel-dots" onMouseEnter={() => {setCanSlide(false)}} onMouseLeave={() => {setCanSlide(true)}}>
                                                    <div className={`dot pointer ${activeCarousel === 1 ? 'active' : ''}`} onClick={() => {setActiveCarousel(1)}}></div>
                                                    <div className={`dot pointer ${activeCarousel === 2 ? 'active' : ''}`} onClick={() => {setActiveCarousel(2)}}></div>
                                                    <div className={`dot pointer ${activeCarousel === 3 ? 'active' : ''}`} onClick={() => {setActiveCarousel(3)}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
