import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import '../../../css/faq-accordion.scss'
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function ShopperRoiPages({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(4) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className='tab-content-div'>
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='heading'>This is how it works</h6>
                                        <h6 className='sub-heading'>1. Omnichannel ROAS measurement</h6>
                                        <div className="sub-content-div">
                                            <p>Introducing Shopper ROI : The ultimate solution for measuring the ROI of your entire shopper marketing ecosystem. Our platform seamlessly integrates data from offline shopper activities, retail media networks, and coupon redemptions, providing a comprehensive view of your Shopper  marketing performance. Powered by advanced Causal AI algorithms, reveal the true impact of each channel on your overall Return on Ad Spend (ROAS). This enables you to make data-driven decisions, optimize your marketing mix, and maximize ROI across all touchpoints.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/shopper-roi/9.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/shopper-roi/10.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Offline & Online Shopper marketing ROI</h6>
                                        <div className="sub-content-div">
                                            <p className='mb-0'>Comprehensive tool to evaluate marketing effectiveness across channels. Tracks customer behavior, purchase patterns, and segment responsiveness. Measures impact of in-store promotions, demos, and sampling. Optimizes strategies for maximum ROI. Integrates online and offline data for holistic view. Provides actionable insights to improve targeting, allocation, and campaign performance. Enables data-driven decisions to enhance shopper engagement and boost sales.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Retail Media Halo (Amazon/ RMN ROI on offline sales)</h6>
                                        <div className="sub-content-div">
                                            <p>Our solution quantifies how online retail media affects offline sales, tracking ROI across major platforms (Amazon, Walmart, Target, Kroger, other RMNs).</p>
                                            <p>Features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Holistic campaign performance view</p>
                                                </li>
                                                <li>
                                                    <p>Precise measurement of online media's influence on in-store purchases</p>
                                                </li>
                                                <li>
                                                    <p>Actionable insights for media spend optimization</p>
                                                </li>

                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Maximizes total sales impact</p></li>
                                                <li><p>Leverages full potential of retail media investments</p></li>
                                                <li><p>Captures e-commerce and brick-and-mortar sales uplift</p></li>
                                            </ul>

                                            <p className='mb-0'>Bridge the online-offline divide and optimize your retail media strategy</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/shopper-roi/11.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/shopper-roi/12.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Predictive Modeling and Scenario Planning</h6>
                                        <div className="sub-content-div">
                                            <p>Cutting-edge tool leveraging Causal AI for predictive modeling and scenario planning.</p>
                                            <p>Simulates future marketing initiatives' impact across channels.</p>
                                            <p>Optimizes budget allocation and channel mix through advanced algorithms.</p>
                                            <p>Identifies risks and opportunities based on historical data and market trends.</p>
                                            <p>Enables "what-if" analyses to forecast outcomes of various strategies.</p>
                                            <p className='mb-0'>Provides actionable insights for maximizing ROI and market share.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopperRoiPages
