import React, { useRef, useEffect, useState } from 'react'
import "./screen22.scss"
import { MdChevronRight } from 'react-icons/md';
import backgroundVideo from "../../assets/cockpit-video.mp4"

function Screen22({activePage, setActivePage}) {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

    const [shownTab, setShownTab] = useState('');

    useEffect(() => {
        document.getElementById('shake-div').classList.add('granular');
    }, [activePage])

    useEffect(() => {
        return () => {
            setShownTab('');
        }
    }, [])
    
    return (
        <div className={'full-screen screen22'} key="screen22">
            {/* <div className="background-image"></div> */}
            {!isLoaded && 
            <div className="background-image"></div>
            }
            <video
                className={isLoaded ? "background-video" : "d-none"}
                ref={videoRef}
                src={backgroundVideo}
                onLoadedData={() => { setIsLoaded(true) }}
                autoPlay loop muted>
            </video>
            <div className="mask"></div>
            <div className="cockpit-top"></div>
            <div className="cockpit-bottom"></div>
            <div className="ellipse"></div>
            <div className="bootstrap-content content">
                <h3 className="text mb-0">
                    It takes you to places, <br />
                    where no other analytics tool has gone before.
                </h3>
                <h4 className="text">
                    Choose an outcome
                </h4>
                <div className="container-fluid">
                    <div className='row align-items-center justify-content-center'>
                        <div className="col-12 col-lg-7">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center">
                                    <div className="button-cust granular mx-lg-auto text-white right" id="shake-div" onMouseOver={() => {
                                        document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('granular');
                                    }}>
                                        <span>Granular ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'granular' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                Investments are made at a highly granular level, targeting specific audiences and platforms. but measured at a media and channel level. <br />
                                                Result : Underreported ROI We leverage Causal AI and Deep Learning techniques to provide unprecedented depth in ROI measurement.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    localStorage.removeItem('activeTab');
                                                    localStorage.setItem('activeTab', 'granular')
                                                    setActivePage(10)
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="after">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 text-center mt-lg-4">
                                    <div className="button-cust mx-lg-auto text-white left" onMouseOver={() => {
                                        document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('agile')}}>
                                        <span>Agile Monthly ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'agile' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                Traditional measurement methods can take several months to collect data, cleanse and model. This ROI data is outdated by the time it becomes available. <br />
                                                We leverage Causal AI and transfer learning techniques to deliver monthly ROI models with just a 2-week lag.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    localStorage.removeItem('activeTab');
                                                    localStorage.setItem('activeTab', 'agile')
                                                    setActivePage(10)
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="before">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 text-center mt-lg-2">
                                    <div className="button-cust text-white left mx-lg-auto" onMouseOver={() => {
                                        document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('longTerm')}}>
                                        <span>Long-term ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'longTerm' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                Measuring long-term ROI alongside short-term metrics is crucial for sustainable business growth. Data POEM's LT ROI product leverages causal AI to accurately attribute long-term value to marketing activities, enabling better strategic decisions and optimized resource allocation.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    localStorage.removeItem('activeTab');
                                                    localStorage.setItem('activeTab', 'longTerm')
                                                    setActivePage(10)
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="before">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-lg-4 mt-xxl-5 justify-content-end">
                                <div className="col-12 col-lg-4 text-center mt-lg-3">
                                    <div className="button-cust mx-lg-auto text-white right" onMouseOver={() => {
                                        document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('shopper')}}>
                                        <span>Shopper ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'shopper' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                Incorporating in-store purchases and shopper behavior into ROAS measurement remains a hurdle. <br />
                                                We provide unprecedented insights into the performance of your offline shopper marketing, retail media networks and coupon campaigns.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    localStorage.removeItem('activeTab');
                                                    localStorage.setItem('activeTab', 'shopper')
                                                    setActivePage(10)
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="after">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-lg-4">
                                    <div className="button-cust text-white left mx-lg-auto" onMouseOver={() => {
                                        document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('dataPoem')}}>
                                        <span>(Your MMM) <sup>Data Poem</sup></span>
                                        <div className={`roi-card text-start ${shownTab === 'dataPoem' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                MMMs are fantastic. They are great at providing high-level insights. But they have a few blindspots. Like measuring interactions, Long term ROI  for example. <br />
                                                Data Poem can complement MMMs in powerful ways, leveraging the strengths of both methods.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    localStorage.removeItem('activeTab');
                                                    localStorage.setItem('activeTab', 'dataPoem')
                                                    setActivePage(10)
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="before">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-2 col-xl-3">
                            <div className="button-cust text-white left" onMouseOver={() => {
                                document.getElementById('shake-div').classList.remove('granular');
                                setShownTab('inFlight')}}>
                                <span>In-flight Optimisation</span>
                                <div className={`roi-card text-start ${shownTab === 'inFlight' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                    <p>
                                        Traditional models often rely on linear regression that can become computationally intensive and difficult to scale as the number of variables and interactions increase. <br />
                                        We capture non-linear relationships, Interactions, adapt to dynamic environments, process and enable in-flight optimization.
                                    </p>
                                    <button
                                        onClick={() => {
                                            localStorage.removeItem('activeTab');
                                            localStorage.setItem('activeTab', 'inFlight')
                                            setActivePage(10)
                                        }}
                                    >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                </div>
                                <div className="before">
                                    <div className="pulse"></div>
                                    <div className="pulse"></div>
                                    <div className="pulse"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-center">
                <div className='bootstrap-content'>
                    <div className='text-center'>
                        <div className='mb-2'>
                            <img src={require('../../assets/images/outcome/bottom-img.png')} alt="" className='center-right' />
                        </div>
                        <div onClick={() => { setActivePage(5) }}>
                            <img src={require('../../assets/images/chevron-down.png')} className='downArrow' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Screen22