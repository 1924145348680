import React, { useState } from 'react'
import "./mainscreen.scss"
import { MdChevronRight, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineHome } from 'react-icons/md'

const MainScreen = ({activePage, setActivePage}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        // setActiveButton('dropdown');
    };

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="dataHub">
                        <div className="tabs">
                          <button
                              className={'btn btn-black home-btn text-white'}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    setActivePage(4)
                                }}
                            >
                              <MdOutlineHome size={24} />
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'granular')
                                    setActivePage(10)
                                }}
                                // onClick={() => handleButtonClick('granular')}
                            >
                              Granular ROI
                          </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    localStorage.removeItem('activeTab');
                                    localStorage.setItem('activeTab', 'dataPoem')
                                    setActivePage(10)
                                }}
                            >
                              (Your MMM) <sup>Data Poem</sup>
                          </button>
                          <button
                              className={`btn btn-black text-white`}
                              onClick={() => {
                                localStorage.removeItem('activeTab');
                                localStorage.setItem('activeTab', 'shopper')
                                setActivePage(10)
                              }}
                              // onClick={() => handleButtonClick('shopper')}
                          >
                              Shopper ROI
                          </button>
                          <button
                              className={`btn btn-black text-white`}
                              onClick={() => {
                                localStorage.removeItem('activeTab');
                                localStorage.setItem('activeTab', 'shopper')
                                setActivePage(10)
                              }}
                              // onClick={() => handleButtonClick('agile')}
                          >
                              Agile Monthly ROI
                          </button>
                          <button
                              className={`btn btn-black text-white`}
                              onClick={() => {
                                localStorage.removeItem('activeTab');
                                localStorage.setItem('activeTab', 'longTerm')
                                setActivePage(10)
                              }}
                              // onClick={() => handleButtonClick('longTerm')}
                          >
                              Long-term ROI
                          </button>
                          <button
                              className={`btn btn-black text-white`}
                              onClick={() => {
                                localStorage.removeItem('activeTab');
                                localStorage.setItem('activeTab', 'inFlight')
                                setActivePage(10)
                              }}
                              // onClick={() => handleButtonClick('inFlight')}
                          >
                              In-flight Optimization
                          </button>
                          <div className="dropdown">
                              <button
                                  className={`btn btn-black text-white`}
                                  type="button"
                                  onClick={toggleDropdown}
                              >
                                  <span>The AI Engine</span>
                                  <span
                                      className="arrow"
                                  >
                                      {isOpen ?  <MdKeyboardArrowUp size={18} />:<MdKeyboardArrowDown size={18} />}
                                  </span>
                              </button>
                              {isOpen && (
                                  <div className="dropdown-menu show">
                                      <div className="dropdown-item" onClick={() => {
                                        setIsOpen(false)
                                        localStorage.removeItem('activeTab');
                                        localStorage.setItem('activeTab', 'causalAi')
                                        setActivePage(10)
                                      }}>Causal AI</div>
                                      <div className="dropdown-item" onClick={() => {
                                        setIsOpen(false)
                                        localStorage.removeItem('activeTab');
                                        localStorage.setItem('activeTab', 'explainableAi')
                                        setActivePage(10)
                                      }}>Explainable AI</div>
                                      <div className="dropdown-item" onClick={() => {
                                        setIsOpen(false)
                                        localStorage.removeItem('activeTab');
                                        localStorage.setItem('activeTab', 'nonlinearOptimization')
                                        setActivePage(10)
                                      }}>Non Linear Optimization</div>
                                  </div>
                              )}
                          </div>
                          <button
                              className={`btn btn-black text-white active`}
                              onClick={() => {
                                localStorage.removeItem('activeTab');
                                // localStorage.setItem('activeTab', 'dataHub')
                                setActivePage(6)
                              }}
                          >
                              The Data Hub
                          </button>
                        </div>
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                  <div className="row align-items-start justify-content-end">
                                      <div className="col-12 w-lg-70 w-80 content-div data-div">
                                          <h6 className='heading'>The Data Hub</h6>
                                          <p className='heading-para'>
                                            Data. It's everywhere. Businesses collect mountains of it. <br /> 
                                            From customer interactions and marketing campaigns to website analytics and sales figures. <br /> 
                                            But here's the problem : this data often sits isolated in silos. Like whispers in separate rooms. <br /> 
                                            We have taken data from 100s of sources and seamlessly stitched them together. <br /> 
                                            In a manner never attempted before.
                                          </p>
                                          <button className='dive-btn'
                                            onClick={() => {
                                                localStorage.removeItem('activeTab');
                                                localStorage.setItem('activeTab', 'dataHub')
                                                setActivePage(10)
                                            }}
                                          >See How <MdChevronRight /></button>
                                      </div>
                                  </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
