import { useEffect, useRef, useState } from "react";
import Screen1 from "./screens/screen1/Screen1";
import Screen2 from "./screens/screen2/Screen2";
import Screen20 from "./screens/screen2/Screen20";
import Screen21 from "./screens/screen2/Screen21";
import Screen22 from "./screens/screen2/Screen22";
import Screen3 from "./screens/screen3/Screen3";
import Screen4 from "./screens/screen4/Screen4";
import Screen5 from "./screens/screen5/Screen5";
import Screen6 from "./screens/screen6/Screen6";
import Screen7 from "./screens/screen7/Screen7";
import { isMobile } from 'react-device-detect';
import Screen8 from "./screens/screen8/Screen8";
import FullScreen from "./screens/screen8/fullscreen/FullScreen";
import FaqScreen from "./screens/faqscreen/FaqScreen";
import { FaArrowDown, FaArrowUp, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
// import ReadOurThoughts from "./screens/readourthoughts/ReadOurThoughts";

export const Slider = ({value, setActivePage, totalScreens}) => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const startDrag = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  const onDrag = (e) => {
    if (!isDragging) return;

    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const offsetY = e.clientY - rect.top;
    const newValue = Math.min(1, Math.max(0, offsetY / rect.height));
    setActivePage(Math.round(newValue * totalScreens) < 9 ? Math.round(newValue * totalScreens) : 9);
  };

  useEffect(() => {
    const handleMouseMove = (e) => onDrag(e);
    const handleTouchMove = (e) => onDrag(e.touches[0]);

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('mouseup', stopDrag);
      window.addEventListener('touchend', stopDrag);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('mouseup', stopDrag);
      window.removeEventListener('touchend', stopDrag);
    };
  }, [isDragging]);

  return (<>
    <div className="sliderDiv" ref={sliderRef} onMouseDown={startDrag} onTouchStart={startDrag}>
      <div className="bar" style={{height: `${value*100}%`}}></div>
      <div className="sliderDot" style={{top: `${value*100}%`}}></div>
    </div>
  </>)
}

function App() {
  // Content
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState(0)
  const [direction, setDirection] = useState(0); // 0: no direction, 1: down, -1: up
  const [scrolling, setScrolling] = useState(true); // 0: no direction, 1: down, -1: up
  const totalScreens = 10;
  const [canScroll, setCanScroll] = useState(true);
  const [warnVisible, setWarnVisible] = useState(true);

  const setActive = (direction) => {
    const newActivePage = Math.max(0, Math.min(activePage + direction, totalScreens - 1)); // Clamp activePage within bounds
    setActivePage(newActivePage);
    setDirection(direction);
  };

  function throttle(func, delay) {
    let lastCall = 0;
    return function(...args) {
      const now = new Date().getTime();
      if (now - lastCall < delay) {
        return;
      }
      lastCall = now;
      return func(...args);
    };
  }
  
  useEffect(() => {
    const handleScroll = throttle((event) => {
      if (!scrolling || !canScroll || activePage === 10 || activePage === 12) return;

      setScrolling(false);
      const newDirection = event.deltaY > 0 ? 1 : -1;
  
      // Check for valid scrolling and adjust activePage accordingly
      if (
        (newDirection === 1 && activePage < totalScreens - 1) ||
        (newDirection === -1 && activePage > 0)
      ) {
        setActive(newDirection);
      }

      setTimeout(() => {
        setScrolling(true);
      }, 1000); // Adjust timeout as needed
    }, 1000);
  
    window.addEventListener('wheel', handleScroll);
  
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [activePage, scrolling, canScroll, totalScreens]); // Add necessary dependencies

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!canScroll || activePage === 10 || activePage === 12) return;
      if (event.key === 'ArrowDown' && activePage < totalScreens - 1) {
        if(warnVisible === true){
          setWarnVisible(false)
        }
        if(activePage < 10){
          setActivePage((prevPage) => prevPage + 1);
        }
      } else if (event.key === 'ArrowUp' && activePage > 0) {
        if(warnVisible === true){
          setWarnVisible(false)
        }
        if(activePage > 0){
          setActivePage((prevPage) => prevPage - 1);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [canScroll, activePage, totalScreens]);

  
  useEffect(() => {
    if(isMobile){
      window.location.href = 'https://m.datapoem.com';
    }
  }, [])

  return (<>
    {!isMobile && <div className="app">
    {/* {<div className="app"> */}
    {/* {(activePage !== 10 && activePage !== 12) && <div className="top-left">
      <img src={require('./assets/images/top-left.png')} alt="" />
    </div>}
    {(activePage === 10 || activePage === 12) && <div className="top-left">
      <img src={require('./assets/images/top-left-internal.png')} alt="" />
    </div>} */}
    {(activePage !== 10 && activePage !== 12) && <div className="logo-center" onClick={() => {
      setActivePage(0)
      setIsSidebarOpen(false)  
    }}>
      <img src={require('./assets/images/DP-Logo.png')} alt="" />
    </div>}
    {(activePage === 10 || activePage === 12) && <div className="logo-center" onClick={() => {
      setActivePage(0)
      setIsSidebarOpen(false)  
    }}>
      <img src={require('./assets/images/DP_AI_LOGO-White.png')} alt="" />
    </div>}
    {(activePage !== 10 && activePage !== 12) && <div className="top-right" onClick={() => {setIsSidebarOpen(true)}}>
      <img src={require('./assets/images/menu.png')} alt="" />
    </div>}
    {(activePage === 10 || activePage === 12) && <div className="top-right" onClick={() => {setIsSidebarOpen(true)}}>
      <img src={require('./assets/images/menu-dark.png')} alt="" />
    </div>}
    {(activePage !== 10 && activePage !== 9 && activePage !== 12) && <div className="contact-menu" onClick={() => {setActivePage(9)}}>
      <img src={require('./assets/images/contact-us-white.png')} alt="" />
    </div>}
    {(activePage === 10 || activePage === 12) && <div className="contact-menu" onClick={() => {setActivePage(9)}}>
      <img src={require('./assets/images/contact-us-black.png')} alt="" />
    </div>}
    <div className={isSidebarOpen ? "bootstrap-content sidebar open" : 'sidebar close'}>
      <div className="text-end">
        <button onClick={() => {setIsSidebarOpen(false)}}>x</button>
      </div>
      <h3 onClick={() => {setIsSidebarOpen(false); setActivePage(8)}}>Get To Know Us</h3>
      {/* <h3>Read Our Thoughts</h3> */}
      <h3 onClick={() => {
        setIsSidebarOpen(false); 
        // localStorage.setItem('setPrev', activePage);
        setActivePage(12);
      }}>FAQs</h3>
      <h3 onClick={() => {setIsSidebarOpen(false); setActivePage(9)}}>Let's Meet</h3>
      <img src={require('./assets/images/sidebar-right.png')} alt="" />
    </div>
    <div className="screens" onClick={() => {setIsSidebarOpen(false)}}>
      <div className={`section ${activePage === 0 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen1 activePage={activePage} setActivePage={setActivePage} />
      </div>
      <div className={`section ${activePage === 1 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen2 activePage={activePage} setActivePage={setActivePage} />
      </div>
      <div className={`section ${activePage === 2 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen20 activePage={activePage} setActivePage={setActivePage} />
      </div>
      <div className={`section ${activePage === 3 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen21 activePage={activePage} setActivePage={setActivePage} />
      </div>
      <div className={`section ${activePage === 4 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen22 activePage={activePage} setActivePage={setActivePage} />
      </div>
      {activePage === 5 && <div className={`section ${activePage === 5 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen4 activePage={activePage} setActivePage={setActivePage} />
      </div>}
      <div className={`section ${activePage === 6 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen5 activePage={activePage} setActivePage={setActivePage} />
      </div>
      {activePage === 7 && <div className={`section ${activePage === 7 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen8 activePage={activePage} setActivePage={setActivePage} setCanScroll={setCanScroll} />
      </div>}
      {/* <div className={`section ${activePage === 8 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <ReadOurThoughts activePage={activePage} setActivePage={setActivePage} />
      </div> */}
      <div className={`section ${activePage === 8 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen6 activePage={activePage} setActivePage={setActivePage} />
      </div>
      <div className={`section ${activePage === 9 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <Screen7 activePage={activePage} setActivePage={setActivePage} />
      </div>
    </div>
    {activePage === 10 && <div className={`section ${activePage === 10 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
      <Screen3 activePage={activePage} setActivePage={setActivePage} />
    </div>}
    {activePage === 11 && <div className={`section ${activePage === 11 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <FullScreen activePage={activePage} setActivePage={setActivePage} setCanScroll={setCanScroll} />
      </div>}
    {activePage === 12 && <div className={`section ${activePage === 12 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
        <FaqScreen activePage={activePage} setActivePage={setActivePage} />
      </div>}
    {activePage !== 10 && activePage !== 11 && activePage !== 12 && <div className="right-center">
      <FaChevronUp className={`${activePage > 0 ? 'pointer' : ''}`} onClick={() => {
        if(activePage > 0){
          if(activePage === 7){
            setCanScroll(true)
          }
          setActivePage(activePage-1);
        }
      }} color="#fff" />
      <Slider value={(activePage === 9 ? 10 : activePage)/totalScreens} setActivePage={setActivePage} totalScreens={totalScreens} />
      <FaChevronDown className={`${activePage < 9 ? 'pointer' : ''}`} onClick={() => {
        if(activePage < 9){
          if(activePage === 7){
            setCanScroll(true)
          }
          setActivePage(activePage+1)
        }
      }} color="#fff" />
    </div>}
    {warnVisible && canScroll && activePage !== 10 && activePage !== 11 && activePage !== 12 && <div className="bottom-left">
      <p>Use keyboard arrows to navigate</p>
      <div>
        <IoArrowUp />
        <IoArrowDown />
      </div>
    </div>}
  </div>
  }
  </>);
}

export default App;
