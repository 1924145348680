import React, { useState } from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';

function Datapoem({ activeButton, setActiveButton, setActivePage }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setIsOpen(false);
    };
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white ${activeButton === 'home' ? 'active' : ''}`}
                                // onClick={() => handleButtonClick('home')}
                                onClick={() => { setActivePage(4) }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('granular')}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataPoem')}
                            >
                                (Your MMM) <span className="text-right">Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('shopper')}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('agile')}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('longTerm')}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('inFlight')}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white ${isOpen === 'dropdown' ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => handleButtonClick('causalAi')}>Causal AI</button>
                                        <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => handleButtonClick('explainableAi')}>Explainable AI</button>
                                        <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => handleButtonClick('nonlinearOptimization')}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('dataHub')}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className='tab-content-div'>
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='heading'>This is how it works</h6>
                                        <h6 className='sub-heading'>1. Enhancing MMM with Causal AI</h6>
                                        <div className="sub-content-div">
                                            <p>Marketing Mix Models (MMMs) identify key marketing drivers and establish baseline models. They provide: </p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Primary marketing lever identification</p>
                                                </li>
                                                <li>
                                                    <p>Initial channel effectiveness quantification</p>
                                                </li>
                                                <li>
                                                    <p>Comparative benchmark establishment</p>
                                                </li>
                                            </ul>
                                            <p>MMM insights inform advanced deep learning by highlighting relevant variables, suggesting interactions, and identifying time-lagged impacts. This hybrid approach:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Improves model interpretability</p></li>
                                                <li><p>Enhances predictive accuracy</p></li>
                                                <li><p>Streamlines modeling</p></li>
                                            </ul>
                                            <p className='mb-0'>Combining traditional methods with deep learning leads to more robust, actionable marketing insights.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/data-poem/1.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/data-poem/2.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Ensemble Modeling and Hybrid Approaches</h6>
                                        <div className="sub-content-div">
                                            <p>
                                                Integrating Marketing Mix Models and deep learning offers:
                                            </p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Cross Channel Synergies/ Interactions</p>
                                                </li>
                                                <li>
                                                    <p>Reduced bias</p>
                                                </li>
                                                <li>
                                                    <p>Granularity</p>
                                                </li>
                                                <li>
                                                    <p>Balanced interpretability and predictive power</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Robust predictions from aggregated outputs</p></li>
                                                <li><p>Capture of linear and non-linear relationships</p></li>
                                                <li><p>Adaptability to market changes</p></li>
                                            </ul>
                                            <p>Enables:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Comprehensive mix optimization</p></li>
                                                <li><p>Confident decision-making</p></li>
                                                <li><p>Better handling of complex challenges</p></li>
                                            </ul>
                                            <p className='mb-0'>This hybrid approach provides nuanced understanding of marketing effectiveness, leading to optimized strategies and improved ROI across campaigns and channels.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Capturing Non-Linear and Complex Interactions</h6>
                                        <div className="sub-content-div">
                                            <p>MMMs capture linear relationships, while deep learning reveals non-linear patterns. This combination offers:</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Comprehensive marketing view</p>
                                                </li>
                                                <li>
                                                    <p>Detection of saturation points</p>
                                                </li>
                                                <li>
                                                    <p>Channel synergy identification</p>
                                                </li>
                                                <li>
                                                    <p>Threshold effect capture</p>
                                                </li>
                                            </ul>
                                            <p>Integrating MMM with deep learning:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Uncovers hidden data patterns</p></li>
                                                <li><p>Models complex customer journeys</p></li>
                                                <li><p>Optimizes across spend levels</p></li>
                                            </ul>
                                            <p>Enables nuanced decisions:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Fine-tuned budget allocation</p></li>
                                                <li><p>Improved campaign timing</p></li>
                                                <li><p>Enhanced cross-channel optimization</p></li>
                                            </ul>
                                            <p className='mb-0'>Result: More accurate, actionable understanding of marketing effectiveness, leading to optimized strategies and improved ROI.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/data-poem/3.jpg')} alt="" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/data-poem/4.jpg')} alt="" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Granularity</h6>
                                        <div className="sub-content-div">
                                            <p>Unlock hyper-targeted optimization to fine-tune strategies at the most detailed level for maximum ROI.</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Precisely measure ROI for each marketing channel (e.g., TV, digital, print).</p>
                                                </li>
                                                <li>
                                                    <p>Evaluate performance of individual tactics within channels (e.g., display ads vs. search ads).</p>
                                                </li>
                                                <li>
                                                    <p>Assess impact of specific creative elements on campaign success.</p>
                                                </li>
                                                <li>
                                                    <p>Measure short-term vs. long-term effects of marketing activities.</p>
                                                </li>
                                                <li>
                                                    <p>Analyze ROI variations across different regions or markets.</p>
                                                </li>
                                                <li>
                                                    <p>Understand how marketing effectiveness varies by customer group.</p>
                                                </li>
                                                <li>
                                                    <p>Quantify interaction effects between different marketing efforts.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Datapoem
