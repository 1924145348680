import React, { useEffect, useState } from 'react'
import "./screen3.scss"
import Datapoem from '../../components/your-mmm-datapoem/datapoem-pages/Datapoem';
import GranularRoiPages from '../../components/granular-roi/granular-roi-pages/GranularRoiPages';
import ShopperRoiPages from '../../components/shopper-roi/shopper-roi-pages/ShopperRoiPages';
import AgileMonthlyRoi from '../../components/agile-monthly-roi/agile-monthly-roi/AgileMonthlyRoi';
import LongTermRoi from '../../components/long-term-roi/long-term-roi/LongTermRoi';
import InflightOptimization from '../../components/inflight-optimization/inflight-optimization/InflightOptimization';
import DataHub from '../../components/datahub/datahub/DataHub';
import CausalAi from '../../components/causal-ai/causal-ai/CausalAi';
import ExplainableAi from '../../components/explainable-ai/explainable-ai/ExplainableAi';
import NonlinearOptimization from '../../components/nonlinear-optimization/nonlinear-optimization/NonlinearOptimization';

function Screen3({ activePage, setActivePage }) {
  const [activeButton, setActiveButton] = useState('');

  useEffect(() => {
    (async () => {
      let activeTab = localStorage.getItem('activeTab');
      if(activeTab){
        setActiveButton(activeTab);
      }
    })()

    // return () => {
    //   localStorage.removeItem('activeTab')
    // }
  }, [])

  return (
    <div className={'full-screen screen3'} key="screen3">
      <div className="content">
        {activeButton === 'granular' && <GranularRoiPages activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'dataPoem' && <Datapoem activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'shopper' && <ShopperRoiPages activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'agile' && <AgileMonthlyRoi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'longTerm' && <LongTermRoi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'inFlight' && <InflightOptimization activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'dataHub' && <DataHub activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'causalAi' && <CausalAi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'explainableAi' && <ExplainableAi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
        {activeButton === 'nonlinearOptimization' && <NonlinearOptimization activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} />}
      </div>
      <div className="bottom-right"></div>
    </div>
  )
}

export default Screen3